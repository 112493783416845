var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    {
      ref: "app",
      class: {
        "bg-light": !_vm.$vuetify.theme.dark,
        "bg-dark": _vm.$vuetify.theme.dark,
      },
      staticStyle: { overflow: "hidden", "max-height": "100vh" },
    },
    [
      _vm.$store.state["toolbar"]["visible"]
        ? _c("asyent-toolbar", { attrs: { standalone: "" } })
        : _vm._e(),
      _c(
        "v-main",
        {
          staticStyle: { overflow: "auto", "max-height": "100%" },
          attrs: { app: "" },
        },
        [_vm._t("default")],
        2
      ),
      _c("asyent-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }