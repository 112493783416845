var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    {
      ref: "app",
      class: {
        "bg-light": !_vm.$vuetify.theme.dark,
        "bg-dark": _vm.$vuetify.theme.dark,
      },
      staticStyle: { overflow: "hidden", "max-height": "100vh" },
    },
    [
      _vm.$store.getters["menu/drawerMenu"].length > 0
        ? _c("asyent-drawer", {
            attrs: {
              "drawer-menu": _vm.$store.getters["menu/drawerMenu"],
              app: "",
            },
          })
        : _vm._e(),
      _vm.$store.state["toolbar"]["visible"]
        ? _c("asyent-toolbar", { attrs: { standalone: false } })
        : _vm._e(),
      _c(
        "v-main",
        {
          staticStyle: { overflow: "auto", "max-height": "100%" },
          attrs: { app: "" },
        },
        [_vm._t("default")],
        2
      ),
      _c("asyent-footer"),
      _vm.appConfig.navigation.floatingNavigation &&
      _vm.$store.getters["menu/drawerMenu"].length > 0
        ? _c(
            "v-menu",
            {
              staticStyle: { "z-index": "99", overflow: "auto" },
              attrs: {
                "aria-label": "Application Menu",
                bottom: "",
                "offset-y": "",
                "offset-x": "",
                "open-on-click": "",
                "offset-overflow": "",
                "close-on-content-click": false,
                "max-height": "500",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              class: { "fab-btn": true },
                              attrs: {
                                "aria-label": "Open Application Menu",
                                bottom: "",
                                color: "primary",
                                fab: "",
                                fixed: "",
                                width:
                                  _vm.appConfig.navigation.fabIconSize[0] +
                                  "px",
                                height:
                                  _vm.appConfig.navigation.fabIconSize[0] +
                                  "px",
                                right: "",
                              },
                            },
                            on
                          ),
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  size: _vm.appConfig.navigation.fabIconSize[1],
                                },
                              },
                              [_vm._v("mdi-menu")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                2710624614
              ),
            },
            [
              _c("asyent-drawer-tree", {
                attrs: {
                  "drawer-menu": _vm.$store.getters["menu/drawerMenu"],
                  "div-color": "var(--v-drawer-base)",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }